.container-fluid {
  padding: 0;
}

.home {
  padding: 120px 0 0;

  .btn-large {
    align-items: center;
    backface-visibility: hidden;
    border-radius: 5px;
    background-color: $brand-blue;
    color: $white;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    font-weight: bold;
    justify-content: center;
    height: 300px;
    padding: 0 20px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: $brand-orange;
      color: $white;
      transform: scale(1.05);
    }

    i {
      font-size: 110px;
    }
  }
}

.regions-container {
  display: flex;
  flex-wrap: wrap;
  padding: 60px 160px;
  justify-content: space-between;

  a {
    background-color: $light-gray;
    border: 1px solid $brand-gray;
    border-radius: 5px;
    color: $brand-gray;
    flex: 1 1 160px;
    font-family: $primary-font;
    font-size: 24px;
    margin: 30px 20px;
    padding: 30px 20px;
    text-align: center;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: $brand-gray;
      color: $white;
      border-color: $brand-gray;
      text-decoration: none;
      transform: scale(1.05);
    }
  }

  .all-regions {
    display: flex;
    flex: 1;

    button {
      &:hover {
        transform: scale(1.025);
      }
    }
  }
}

.screensaver-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 60px 0;

  .form-group {
    align-items: center;
    background-color: $lighter-gray;
    border-radius: 5px;
    color: $white;
    display: flex;
    justify-content: space-between;
    margin: 30px 0 0;
    padding: 20px;
    position: relative;

    i {
      font-size: 60px;
      height: 70px;
      padding: 0 30px;
      text-align: center;
    }

    label {
      font-size: 24px;
      margin: 0;
      text-align: center;
    }

    input[type=file] {
      flex-basis: 60%;

      ::-webkit-file-upload-button {
        background-color: transparent;
        border: none;
        color: #216cd7;
        cursor: pointer;
        font-family: $primary-font-bold;
        padding: 1em;
        text-transform: uppercase;
      }
    }

    &:hover {
      background-color: $brand-gray;
    }
  }

  button {
    background-color: $brand-blue;
    padding: 20px;
  }
}

.clubs-container {
  width: 100%;

  .title {
    color: $brand-orange;
    font-family: $primary-font-bold;
  }

  .MuiPaper-root {
    box-shadow: 0px 3px 6px -4px #555;

    .MuiTableContainer-root {
      thead {
        tr:first-child {
          th {
            text-transform: uppercase;
            &:nth-child(2) {
              background-color: $brand-green;
              color: $white;
            }

            &:nth-child(3) {
              background-color: $brand-blue;
              color: $white;
            }

            &:nth-child(4) {
              background-color: $brand-gray;
              color: $white;
            }

            &:nth-child(5) {
              background-color: $brand-orange;
              color: $white;
            }
          }
        }
        tr:nth-child(2) {
          th {            
            color: $white;
            font-family: $primary-font-lean;
            &:nth-child(1) {
              color: inherit;
            }

            &:nth-child(2) {
              background-color: $brand-green;
              border-right: 1px solid $white;
            }

            &:nth-child(3) {
              background-color: $brand-green;
            }

            &:nth-child(4) {
              background-color: $brand-blue;
              border-right: 1px solid $white;
            }

            &:nth-child(5) {
              background-color: $brand-blue;
            }

            &:nth-child(6) {
              background-color: $brand-gray;
              border-right: 1px solid $white;
            }
            
            &:nth-child(7) {
              background-color: $brand-gray;
            }

            &:nth-child(8) {
              background-color: $brand-orange;
              border-right: 1px solid $white;
            }

            &:nth-child(9) {
              background-color: $brand-orange;
            }
          }
        }
      }

      tbody {
        td {
          &:nth-child(4),
          &:nth-child(10),
          &:nth-child(16),
          &:nth-child(22) {
            border-right: 2px solid $white;
          }

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7) {
            background-color: $green-light;
            color: $brand-green-dark;
          }

          &:nth-child(8),
          &:nth-child(9),
          &:nth-child(10),
          &:nth-child(11),
          &:nth-child(12),
          &:nth-child(13) {
            background-color: $blue-light;
            color: $brand-blue;
          }

          &:nth-child(14),
          &:nth-child(15),
          &:nth-child(16),
          &:nth-child(17),
          &:nth-child(18),
          &:nth-child(19) {
            background-color: $gray-light;
            color: $brand-gray;
          }

          &:nth-child(20),
          &:nth-child(21),
          &:nth-child(22),
          &:nth-child(23),
          &:nth-child(24),
          &:nth-child(25) {
            background-color: $orange-light;
            color: $brand-orange;
          }
        }
      }
    }

    .MTableToolbar-highlight-6 {
      background-color: $white;
    }
  }
}

.promocodes-container {
  position: relative;
  width: 100%;

  .MuiPaper-root {
    box-shadow: 0px 3px 6px -4px #555;
  }

  .range-picker {
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;

    .rdrDateRangePickerWrapper {
      border: 1px solid #aaa;
      border-radius: 5px;
      box-shadow: 0 0 20px #444;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      .rdrDefinedRangesWrapper {
        border-radius: 5px 0 0 5px;

        .rdrStaticRanges {
          button {
            &:first-of-type {
              border-radius: 5px 0 0 0;
            }
          }
        }
      }

      .rdrCalendarWrapper {
        border-radius: 0 5px 5px 0;

        .rdrDateDisplayWrapper {
          border-radius: 0 5px 0 0;
        }
      }
    }
  }
}

.update-products-container {

  .MuiPaper-root {
    box-shadow: 0px 3px 6px -4px #555;

    .MuiTableContainer-root {
      thead {
        tr:first-child {
          th {
            background-color: $light-gray-1;
            padding-bottom: 2px;
            text-transform: uppercase;

            &:nth-child(6) {
              border-bottom: 0;
            }
            
            &:nth-child(8) {
              border-bottom: 0;
            }
          }
        }
        tr:nth-child(2) {
          th {            

            &:nth-child(6) {
              background-color: $light-gray-1;
              border-left: 2px solid $light-gray-2;
            }
            
            &:nth-child(7) {
              background-color: $light-gray-1;
              border-right: 2px solid $light-gray-2;
            }
            
            &:nth-child(9) {
              background-color: $light-gray-1;
              border-left: 2px solid $light-gray-2;
            }
            
            &:nth-child(10) {
              background-color: $light-gray-1;
              border-right: 2px solid $light-gray-2;
            }
          }
        }
      }
    }

    .MTableToolbar-highlight-6 {
      background-color: $white;
    }
  }
}

.header {
  align-items: center;
  background-color: $white;
  display: flex;
  height: 60px;
  padding-left: 1.5rem;

  .title {
    align-items: center;
    color: $brand-gray;
    display: flex;
    font-family: $primary-font-bold;
    font-size: 18px;
    height: 100%;
    padding-top: 10px;
    text-transform: uppercase;
  }

  .info {
    background-color: $brand-orange;
    border-radius: 20px;
    color: $white;
    font-size: 14px;
    margin-left: 30px;
    padding: 10px 20px;

    strong {
      font-size: 16px;
    }

    &.blue {
      background-color: $brand-blue;
    }

    &.gray {
      background-color: $brand-gray;
    }
  }
}